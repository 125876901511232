<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('document_requests_title')" :isNewButton="false" :isColumns="true"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('document_requests_title')" :isNewButton="true" :isColumns="true"
					@new-button-click="createFormShow()" @filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</HeaderMobile>
			</template>
			<datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
				:exportExcel="checkPermission('documentrequest_excelexport')" @exportExcel="exportExcel">
				<b-row>
					<b-col sm="6" md="3">
						<b-form-group :label="$t('student_number')">
							<b-form-input type="text" v-model="datatable.queryParams.filter.student_number"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="3">
						<b-form-group :label="$t('national_id_passport_no')">
							<b-form-input type="text" v-model="datatable.queryParams.filter.national_id"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="3">
						<b-form-group :label="$t('name')">
							<b-form-input type="text" v-model="datatable.queryParams.filter.name"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="3">
						<b-form-group :label="$t('surname')">
							<b-form-input type="text" v-model="datatable.queryParams.filter.surname"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4">
						<b-form-group :label="$t('faculty')">
							<faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4">
						<b-form-group :label="$t('program')">
							<program-selectbox v-model="datatable.queryParams.filter.program_code"
								:faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4">
						<b-form-group :label="$t('responsible_person')">
                            <staff-auto-complete
                                v-model="datatable.queryParams.filter.responsible_id"
                            ></staff-auto-complete>
                        </b-form-group>
					</b-col>
					<b-col sm="6" md="3">
						<b-form-group :label="$t('document_type')">
							<document-request-type-selectbox
                                code="document_request_types_filter"
								v-model="datatable.queryParams.filter.document_type"></document-request-type-selectbox>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="3">
						<b-form-group :label="$t('status')">
							<parameter-selectbox code="document_request_statuses"
								v-model="datatable.queryParams.filter.status"></parameter-selectbox>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="2">
						<b-form-group :label="$t('ebys_id')">
							<b-form-input type="number" v-model="datatable.queryParams.filter.ebys_id"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4">
						<b-form-group :label="$t('date')">
							<date-between v-model="datatable.queryParams.filter.date_between"></date-between>
						</b-form-group>
					</b-col>
				</b-row>
			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
				:total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
				@on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
			</datatable>
			<CommonModal ref="detailFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('detail').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<detail-form :formObj="formObj" @updateFormSuccess="detailFormSuccess" v-if="formProcess == 'detail'" />
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import DocumentRequestTypeSelectbox from "@/components/interactive-fields/DocumentRequestTypeSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import DateBetween from "@/components/interactive-fields/DateBetween";

// Pages
import DetailForm from "./DetailForm";

// Services
import DocumentRequestService from "@/services/DocumentRequestService";

// Others
import qs from 'qs'
import { EventBus } from '@/main'

export default {
	components: {
		AppLayout,
		Header,
		HeaderMobile,
		DatatableFilter,
		Datatable,
		CommonModal,
		FacultySelectbox,
		ProgramSelectbox,
		DocumentRequestTypeSelectbox,
		ParameterSelectbox,
		DateBetween,
		DetailForm,
	},
	metaInfo() {
		return {
			title: this.$t('document_requests_title')
		}
	},
	data() {
		return {
			datatable: {
				isLoading: false,
				columns: [
					{
						label: '',
						field: 'buttons',
						html: true,
						sortable: false,
						tdClass: 'p-0 text-center w-40 align-middle',
						buttons: [
							{
								text: this.$t('detail'),
								class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
								permission: 'documentrequest_show',
								callback: (row) => {
									this.detailFormShow(row)
								}
							}
						]
					},
					{
						label: this.toUpperCase('id'),
						field: 'id',
						hidden: true,
					},
					{
						label: this.toUpperCase('student_number'),
						field: 'student_number',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('national_id_passport_no'),
						field: 'national_id',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('name'),
						field: 'name',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('surname'),
						field: 'surname',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('faculty'),
						field: this.getLocaleField('faculty_name'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('program'),
						field: this.getLocaleField('program_name'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('date'),
						field: 'date',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('responsiblePerson'),
						field: 'responsible_name',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('document_type'),
						field: this.getLocaleField('document_type'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('status'),
						field: this.getLocaleField('status'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('ebys_id'),
						field: 'ebys_id',
						hidden: false,
						sortable: false
					}
				],
				rows: [],
				total: 0,
				filterStatus: true,
				showTable: false,
				queryParams: {
					filter: {},
					sort: 'status',
					page: 1,
					limit: 20
				}
			},
			formProcess: null,
			formObj: null,
			form: {},
		}
	},
	created() {
		this.filterSet();
		this.formClear();
	},
	methods: {
		filterSet() {
			this.datatable.queryParams.filter = {
				student_number: null,
			}
		},
		filterClear() {
			this.filterSet()
			this.getRows();
		},
		filter() {
            this.datatable.queryParams.page = 1;
			this.getRows();
		},
		onPageChange(params) {
			this.datatable.queryParams.page = params.currentPage;
			this.getRows();
		},
		onPerPageChange(params) {
			this.datatable.queryParams.limit = params.currentPerPage;
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		changeColumn(key) {
			this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
		},
		onSortChange(params) {
			const sortType = params[0].type == 'desc' ? '-' : '';
			this.datatable.queryParams.sort = sortType + params[0].field
			this.getRows();
		},
		getRows() {
			this.datatable.showTable = true;
			this.datatable.isLoading = true;

			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			return DocumentRequestService.getAll(config)
				.then((response) => {

					this.datatable.rows = (response.data.data || []).map(item => ({ ...item, responsible_name: `${this.isNotNullValue(item.responsible_name, "")} ${this.isNotNullValue(item.responsible_surname)}` }))
					this.datatable.total = response.data.pagination.total
				})
				.finally(() => {
					this.datatable.isLoading = false;
				});
		},

		// Clear
		formClear() {
			this.formObj = null
			this.formProcess = null
			this.getRows()
		},

		// Update
		detailFormShow(obj) {
			this.formObj = obj
			this.formProcess = 'detail'
			this.$refs.detailFormModal.$refs.commonModal.show()
		},
		detailFormSuccess() {
			this.$refs.detailFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},
		exportExcel() {
			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			DocumentRequestService.exportExcel(config).then(res => this._downloadFile(res, this.$t('document_requests_title') + '.xlsx')).catch(err => this.showErrors(err))
		}

	},

	mounted() {
		EventBus.$on('closeDocumentModal', () => {
			this.$refs.detailFormModal.$refs.commonModal.hide()
		})
	}
}
</script>

